body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'GothamLight';
  src: local('GothamLight'),
  url('./components/fonts/Gotham-Font/GothamLight.ttf') format('truetype');
}
@font-face {
  font-family: 'GothamBook';
  src: local('GothamBook'),
  url('./components/fonts/Gotham-Font/GothamBook.ttf') format('truetype');
}
@font-face {
  font-family: 'GothamMedium';
  src: local('GothamMedium'),
  url('./components/fonts/Gotham-Font/GothamMedium.ttf') format('truetype');
}
@font-face {
  font-family: 'GothamBold';
  src: local('GothamBold'),
  url('./components/fonts/Gotham-Font/GothamBold.ttf') format('truetype');
}