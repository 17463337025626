.navbar-container .navbar-top{
    display: flex;
    justify-content: right;
    align-items: center;
    /* padding: 0 24px; */
}
.navbar-container .navbar-down{
}
.navbar-container .navbar-down .Mui-selected{
    color: #BE8C15;
    background-color: rgba(0,0,0,0);
}

.font-face-gb{
    font-family: "GothamBook" !important;
}
.drawer-list-item a{
    text-decoration: none !important;
    color: black !important;
}
.drawer-list-item p{
    text-decoration: none !important;
    color: black !important;
}