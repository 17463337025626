.text-style-h1{
    font-family: "GothamBold" !important;
    font-weight: bold;
    font-size: 32px !important;
    margin: 0 0 4px 0;
    text-transform: uppercase !important;
    /* font-size: 56px; */
}
.text-style-h1-v2{
    font-family: "GothamBold" !important;
    font-weight: bold;
    font-size: 32px !important;
    margin: 0 0 4px 0;
    /* text-transform: uppercase !important; */
    /* font-size: 56px; */
}
.text-style-h2{
    font-family: "GothamBold" !important;
    font-weight: bold;
    font-size: 28px !important;
    line-height: 21px;
    margin: 12px 0;
    /* font-size: 42px; */
}
.text-style-h3{
    font-family: "GothamBold" !important;
    font-weight: bold;
    font-size: 24px !important;
    line-height: 21px;
    margin: 12px 0;
    /* font-size: 36px; */
}
.text-style-h4{
    font-family: "GothamMedium" !important;
    /* font-weight: bold; */
    font-size: 20px !important;
    line-height: 21px;
    /* font-size: 32px; */
}
.text-style-h5{
    font-family: "GothamBook" !important;
    /* font-weight: bold; */
    font-size: 16px !important;
    line-height: 21px;
    /* font-size: 24px; */
}
.text-style-h6{
    font-family: "GothamBook" !important;
    /* font-weight: 400 !important; */
    font-size: 14px !important;
    line-height: 21px;
    /* font-size: 20px; */
}
.text-style-p{
    font-family: "GothamLight" !important;
    /* font-weight: bold; */
    font-size: 11px !important;
    line-height: 21px;
    /* font-size: 18px; */
}

.text-spacing-normal{
    letter-spacing: normal !important;
}
.text-spacing-1{
    letter-spacing: -1px !important;
}
.text-spacing-2{
    letter-spacing: -2px !important;
}
.text-spacing-3{
    letter-spacing: -3px !important;
}
.text-spacing-4{
    letter-spacing: 1px !important;
}
.text-spacing-5{
    letter-spacing: 2px !important;
}
.text-spacing-6{
    letter-spacing: 3px !important;
}

.small-margin-top-bottom{
    margin-top: 8px;
    margin-bottom: 8px;
}
.medium-margin-top-bottom{
    margin-top: 12px;
    margin-bottom: 12px;
}
.big-margin-top-bottom{
    margin-top: 24px;
    margin-bottom: 24px;
}
/* .text-style-medium{
    font-weight: 600 !important;
} */
.text-style-bold{
    font-weight: bold !important;
}

.text-align-center{
    text-align: center;
}
.text-align-left{
    text-align: left;
}
.text-align-right{
    text-align: right;
}
.text-align-justify{
    text-align: justify;
}