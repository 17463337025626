.homepage-container{
    /* background-color: #F5F5F5; */
    /* background-color: #000000; */
    /* background-color: rgb(0, 0, 0, 0); */
}
.homepage-container .home-carousel{
    /* background-image: url('./../../assets/bg/feature1.jpg');
    background-repeat: no-repeat;
    background-size: cover; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.homepage-container .home-carousel-container .home-carousel .home-image-header{
    /* width: 100%;
    height: auto; */
    /* height: 100%;
    width: auto; */
    height: 75vh;
    width: 100%;
    /* border-radius: 12px; */
}
.homepage-container .home-carousel .carousel-content{
    text-align: left;
    padding: 0px 160px 0px 32px;
}
/* .homepage-container .home-carousel-container .img-home-carousel {
    width: 100%;
    height: auto;
} */

/* Button test */
/* button[aria-label='Previous'] {
    background-color: rgba(255,255,255, 0);
    color: rgba(255,255,255, 0);
    opacity: 1;
    border-radius: 5px;
    width: 64px;
    height: 64px;
    font-size: 24px;
} 
button[aria-label='Previous']:hover {
    background-color: rgba(255,255,255, 0.8) !important;
    color: #000 !important;
} 
button[aria-label='Previous'] svg {
    
    width: 32px;
    height: 64px;
} 

button[aria-label='Next'] {
    background-color: rgba(255,255,255, 0);
    color: rgba(255,255,255, 0);
    opacity: 1;
    border-radius: 5px;
    width: 64px;
    height: 64px;
    font-size: 24px;
} 
button[aria-label='Next']:hover {
    background-color: rgba(255,255,255, 0.8) !important;
    color: #000 !important;
} 
button[aria-label='Next'] svg {
    
    width: 32px;
    height: 64px;
}  */

/* question section */
.homepage-container .home-carousel-container, .home-welcome-container, .home-software-solution-container, .home-feature-container, .home-client-container, .home-question-container{
    display: flex;
    justify-content: center;
}
.homepage-container .home-welcome-container{
    display: flex;
    justify-content: space-between;
}
/* .homepage-container .home-welcome-container .polygon-left{

}
.homepage-container .home-welcome-container .polygon-right{
    display: flex;
    align-items: end;
} */
.homepage-container .home-client-container{
    background-color: #f5f5f5;
}

.homepage-container .home-feature-container{
    background-color: #f8fdff;
}

.bg-white{
    height: 100px;
    background-color: #f5f5f5;
}
.bg-gray{
    height: 100px;
    background-color: #fff;
}
.bg-spwhite{
    height: 100px;
    background-color: #f5f5f5;
}
.sp-curved-1{
    background-color: #fff;
    /* box-shadow: 0px 4px 40px rgba(0, 0, 0, .08); */
    border-radius: 0% 0% 100% 100%;
    height: 100px;
}
.sp-curved-2{
    background-color: #f4f4f4;
    /* box-shadow: 0px 4px 40px rgba(0, 0, 0, .08); */
    border-radius: 0% 0% 100% 100%;
    height: 100px;
}
.sp-curved-3{
    background-color: #f8fdff;
    /* box-shadow: 0px 4px 40px rgba(0, 0, 0, .08); */
    border-radius: 0% 0% 100% 100%;
    height: 64px;
}