.client-page{
    margin-top: 18px;
    display: flex;
    justify-content: center;
}

.client-page{
    margin-bottom: 128px;
}

.client-page .toggle-filter{
    margin-bottom: 24px;
}
.client-page .toggle-filter .toggle-button-client{
    border: 0;
    height: 32px;
    border-radius: 64px !important;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 12px;
}
.client-page .toggle-filter .toggle-filter-all{
    height: 32px;
    border-radius: 64px !important;
    /* padding: 0; */
    border: 0;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 12px;
}
.client-page .toggle-filter .Mui-selected{
    background-color: #b98528;
    color: #fff;
    border: 0;
}
.client-page .toggle-filter .Mui-selected:hover{
    background-color: #b98528;
    color: #fff;
    border: 0;
}
.client-page .toggle-filter .Mui-selected:focus{
    border: 0;
    outline: 0;
}

.client-page .grid-item-client img{
    width: 90%;
    height: 120px;
    object-fit: contain;
}