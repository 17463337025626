
/* ABOUT PROFILE */
.about-profile-container{
    margin: 120px 0;
    display: flex;
    justify-content: center;
}

/* ABOUT VISI MISI */
.about-visi-misi-container{
    margin: 120px 0;
    display: flex;
    justify-content: center;
}

/* ABOUT CRITICAL SUCCESS FACTOR */
.about-csf-container{
    margin: 120px 0;
    display: flex;
    justify-content: center;
}

/* ABOUT WHY */
.about-why-container{
    margin: 120px 0;
    display: flex;
    justify-content: center;
}

/* ABOUT PROCESS MODEL */
.about-process-model-container{
    margin: 120px 0;
    display: flex;
    justify-content: center;
}