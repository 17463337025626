.our-client{
    /* margin-bottom: 128px; */
}

.our-client .toggle-filter{
    margin-bottom: 24px;
}
.our-client .toggle-filter .toggle-button-client{
    border: 0;
    height: 32px;
    border-radius: 8px !important;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 12px;
}
.our-client .toggle-filter .toggle-filter-all{
    height: 32px;
    border-radius: 8px !important;
    /* padding: 0; */
    border: 0;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 12px;
}
.our-client .toggle-filter .Mui-selected{
    background-color: #b98528;
    color: #fff;
    border: 0;
}
.our-client .toggle-filter .Mui-selected:hover{
    background-color: #b98528;
    color: #fff;
    border: 0;
}
.our-client .toggle-filter .Mui-selected:focus{
    border: 0;
    outline: 0;
}

.our-client-sidebar{
    text-align: left;
    margin-top: 64px;
}
.our-client-sidebar .our-client-sidebar-title{
    color: #B8892D;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 24px;
}
.our-client-sidebar .our-client-sidebar-desc{
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 16px;
}
.our-client-sidebar .our-client-sidebar-button{
    font-family: 'Raleway', sans-serif;
    color: #b98528;
    border: solid 1px #b98528;
    border-radius: 8px;
    font-weight: bold;
}
.our-client-sidebar .our-client-sidebar-button:hover{
    font-family: 'Raleway', sans-serif;
    color: #fff;
    background-color: #b98528;
    border: solid 1px #fff;
    border-radius: 8px;
    font-weight: bold;
}
.our-client .grid-item-client{
    /* border-radius: 64px; */
}
.our-client .grid-item-client .grid-item-client-card{
    border-radius: 64px;
}
.our-client .grid-item-client img{
    width: 90%;
    height: 100px;
    object-fit: contain;
}