.color-base-white{
    color: #FFFFFF !important;
}
.color-yabes-white{
    color: #F4F4F4 !important;
}
.color-dark-white{
    color: #E8E8E8 !important;
}
.color-base-black{
    color: #000000 !important;
}
.color-yabes-black{
    color: #000000 !important;
}
.color-light-black{
    color: #3A4040 !important;
}
.color-yabes-blue{
    color: #104e85 !important;
}
.color-dark-blue{
    color: #1A5974 !important;
}
.color-light-blue{
    color: #37A3D2 !important;
}
.color-yabes-gold{
    color: #BE8C15 !important;
}
.color-light-gold{
    color: #E7AC23 !important;
}
.color-dark-gold{
    color: #A6770A !important;
}