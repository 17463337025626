.footer-container{

}
.footer-container .divider-footer{
    height: 2px;
    background-color: #e0e0e0;
    /* background-color: #b8b8b8; */
    /* margin-top: 64px; */
}
.footer-container .footer-grid-container .footer-grid-item-logo img{
    display: block;
    margin: 12px 0;
}